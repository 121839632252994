import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services';
import { NotificationToasterService } from '../../services/notification.service';

@Component({
  selector: 'app-user-activation-banner',
  templateUrl: './user-activation-banner.component.html',
  styleUrls: ['./user-activation-banner.component.scss'],
})
export class UserActivationBannerComponent implements OnInit {
  constructor(
    public authenticationService: AuthenticationService,
    private toaster: NotificationToasterService
  ) {}
  loading = false;
  get currentUser() {
    return this.authenticationService.user;
  }

  resend() {
    this.authenticationService.resendActivationCode().subscribe((r) => {
      if (r) {
        this.toaster.success('Email sent successfully');
      } else {
        window.location.reload();
      }
    });
  }

  ngOnInit(): void {}
}
