<!-- <mat-sidenav #sidenav mode="over">
  
</mat-sidenav> -->

<div class="header">
  <div class="logo">
    <!-- drawer on mobile -->
    <mat-drawer-container class="drawer-container" [hasBackdrop]="true">
      <mat-drawer #drawer mode="over" fixedInViewport="true">
        <div class="drawer-header">
          <img
            class="logo"
            height="30"
            src="/assets/add-logo.svg"
            (click)="drawer.toggle()"
            routerLink="/"
            alt=""
          />
          <button class="close-button" (click)="drawer.toggle()">
            <img src="assets/icons/close.svg" />
          </button>
        </div>
        <div class="drawer-content">
          <div class="main-menu">
            <div
              routerLink="/dashboard"
              routerLinkActive="active"
              class="menu-item"
              (click)="drawer.toggle()"
            >
              <img src="assets/icons/dashboard.svg" /> Dashboard
            </div>
            <div
              routerLink="/account"
              routerLinkActive="active"
              class="menu-item"
              (click)="drawer.toggle()"
            >
              <img src="assets/icons/account.svg" /> Account
            </div>
            <div
              routerLink="/subscription"
              routerLinkActive="active"
              class="menu-item"
              (click)="drawer.toggle()"
            >
              <img src="assets/icons/subscription.svg" /> My Plan
            </div>
            <!-- <div
              routerLink="/purchases"
              routerLinkActive="active"
              class="menu-item"
              (click)="drawer.toggle()"
            >
              <img src="assets/icons/purchases.svg" /> Purchases
            </div> -->
            <div
              routerLink="/preferences"
              routerLinkActive="active"
              class="menu-item"
              (click)="drawer.toggle()"
            >
              <img src="assets/icons/preferences.svg" /> Preferences
            </div>
          </div>
          <div class="main-menu extra">
            <div class="menu-item" (click)="openAdd()">
              <img src="assets/icons/app.svg" />Launch ADD Desktop
            </div>
          </div>
          <button
            mat-button
            class="main-menu download-btn"
            (click)="downloadApp()"
          >
            <img
              src="assets/buttons/download-underline.svg"
              class="download-icon"
            />
            Download ADD 2.0
          </button>
        </div>
      </mat-drawer>
      <mat-drawer-content>
        <button class="menu-button" (click)="drawer.toggle()">
          <mat-icon class="menu-icon">menu</mat-icon>
        </button>
      </mat-drawer-content>
    </mat-drawer-container>
    <img
      class="logo"
      height="36"
      src="/assets/add-logo.svg"
      routerLink="/"
      alt=""
    />
    <img
      class="logo-title"
      height="30"
      width="'100%"
      src="/assets/add-logo-title.svg"
      routerLink="/"
      alt=""
    />
  </div>
  <div class="header-menu">
    <div>
      <!--      <a [href]='env.soundsApp'> <img src='/assets/icons/sounds.svg' alt=''> Sounds </a>-->
      <!--      <a [href]='env.communityApp'> <img src='/assets/icons/community.svg' alt=''> Community </a>-->
    </div>
  </div>
  <div *ngIf="currentUser" class="right-menu">
    <div
      (click)="navigateToSounds()"
      routerLinkActive="active"
      class="sounds-app-link"
      *ngIf="isDevEnvironment && !isManagedLogin"
    >
      <img src="assets/icons/sounds-yellow.svg" />
      <span>+Sounds</span>
    </div>
    <div
      class="tokens"
      *ngIf="isDevEnvironment && !isManagedLogin && currentUser.coins"
      (click)="openTokenPacks()"
    >
      {{ authenticationService?.user?.coins }} tokens
      <span class="buy-more"> Buy More</span>
    </div>
    <div
      class="profile-incomplete-wrapper"
      *ngIf="currentUser?.id && !currentUser?.firstName"
    >
      <div class="profile-info-missing">
        Your profile has missing information
      </div>
      <div class="profile-incomplete" routerLink="/account/details">
        Update Profile
      </div>
    </div>
    <div
      [ngClass]="currentUser?.firstName ? 'avatar' : 'avatar inactive'"
      [matMenuTriggerFor]="userMenu"
    >
      <span *ngIf="currentUser?.firstName"
        >{{ currentUser?.firstName?.[0]?.toUpperCase()


















































        }}{{ currentUser?.lastName?.[0]?.toUpperCase() }}
      </span>

      <span *ngIf="!currentUser?.firstName">🍪</span>
    </div>

    <mat-menu #userMenu="matMenu" xPosition="before">
      <div class="user-menu">
        <h3 class="ellipsis">
          {{ currentUser.firstName }}
          {{ currentUser.lastName }}
          <span
            *ngIf="!isManagedLogin"
            class="ellipsis"
            matTooltip="{{ currentUser.email }}"
            >{{ currentUser.email }}</span
          >
          <span
            *ngIf="isManagedLogin"
            class="ellipsis"
            matTooltip="{{ currentUser.email }}"
            >{{ currentUser.username }}</span
          >
        </h3>

        <div class="hr"></div>
        <div
          (click)="navigateToSounds()"
          routerLinkActive="active"
          class="sounds-app-profile-link"
          *ngIf="isDevEnvironment && !isManagedLogin"
        >
          <img src="assets/icons/sounds-yellow.svg" /> Sounds App
        </div>
        <div
          routerLink="/account"
          routerLinkActive="active"
          class="menu-item"
          *ngIf="!isManagedLogin"
        >
          <img src="assets/icons/account.svg" /> Account
        </div>
        <a class="menu-item" href="https://add.app/knowledge-base/">
          <img src="assets/icons/help.svg" /> Knowledge Base
        </a>
        <a class="menu-item" href="https://add.app/contact">
          <img src="assets/icons/contact-us.svg" /> Contact Us
        </a>
        <a class="menu-item" href="https://add.app/">
          <img src="assets/icons/add.app.svg" /> add.app
        </a>
        <div class="hr"></div>
        <div (click)="logout()" class="menu-item">
          <img src="assets/icons/logout.svg" /> Logout
        </div>
      </div>
    </mat-menu>
  </div>
</div>
<app-user-activation-banner></app-user-activation-banner>
<app-account-activation-banner></app-account-activation-banner>
<app-cancel-subscription-banner></app-cancel-subscription-banner>
<app-payment-failed-banner></app-payment-failed-banner>
<div class="main">
  <div class="aside" *ngIf="!isManagedLogin">
    <div class="main-menu">
      <div routerLink="/dashboard" routerLinkActive="active" class="menu-item">
        <img src="assets/icons/dashboard.svg" /> Dashboard
      </div>
      <div routerLink="/account" routerLinkActive="active" class="menu-item">
        <img src="assets/icons/account.svg" /> Account
      </div>
      <div
        routerLink="/subscription"
        routerLinkActive="active"
        class="menu-item"
      >
        <img src="assets/icons/subscription.svg" /> My Plan
      </div>

      <!-- <div routerLink="/purchases" routerLinkActive="active" class="menu-item">
        <img src="assets/icons/purchases.svg" /> Purchases
      </div> -->
      <div
        routerLink="/preferences"
        routerLinkActive="active"
        class="menu-item"
      >
        <img src="assets/icons/preferences.svg" /> Preferences
      </div>
    </div>
    <div class="main-menu extra">
      <div class="menu-item" (click)="openAdd()">
        <img src="assets/icons/app.svg" />Launch ADD Desktop
      </div>
    </div>
    <button
      mat-button
      class="download-btn"
      (click)="downloadApp()"
      style="min-width: 100%; height: 2.5rem"
      [style.opacity]="isDownloading ? 0.5 : 1"
    >
      <app-spinner *ngIf="isDownloading"></app-spinner>
      <div
        class="download-wrapper"
        *ngIf="!isDownloading"
        style="min-width: 100%; height: 100%"
      >
        <img
          src="assets/buttons/download-underline.svg"
          class="download-icon"
        />
        Download ADD 2.0
      </div>
    </button>
  </div>

  <div *ngIf="isManagedLogin">
    <app-managed-user-landing></app-managed-user-landing>
  </div>

  <div class="content" *ngIf="!isManagedLogin">
    <router-outlet></router-outlet>
  </div>
</div>
<mat-divider class="footer-divider"></mat-divider>
<div class="footer">
  <a href="https://add.app/cookie-policy/" target="_blank">Cookie Policy</a>
  <a href="https://add.app/privacy-policy/" target="_blank">Privacy Policy</a>
  <span>© 2024 Audio Design Desk</span>
</div>
