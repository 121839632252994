<div
  class="banner"
  *ngIf="
    currentUser?.emailActivationCode || currentUser?.user_activated === 'N'
  "
>
  <img src="/assets/exclamation-icon.svg" />
  <div>
    <p>Your Account has not been activated yet.</p>
    <span>
      Check your inbox for our activation email. Can’t find it?
      <a (click)="resend()">Re-send</a> activation email.
    </span>
  </div>
</div>
