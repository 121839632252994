import { HelpdeskComponent } from './components/helpdesk/helpdesk.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LogoutComponent } from './components/logout/logout.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionOverviewComponent } from './components/subscription-overview/subscription-overview.component';
import { MaterialModule } from './material.module';
import { RouterModule } from '@angular/router';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { EmailActivationBannerComponent } from './components/email-activation-banner/email-activation-banner.component';
import { ManagedUserLandingComponent } from './components/managed-user-landing/managed-user-landing.component';
import { AccountActivationBannerComponent } from './components/account-activation-banner/account-activation-banner.component';
import { CancelSubscriptionBannerComponent } from './components/cancel-subscription-banner/cancel-subscription-banner.component';
import { CurrentPlanBannerComponent } from './components/current-plan-banner/current-plan-banner.component';
import { UpgradePlanComponent } from './components/upgrade-plan-banner/upgrade-plan-banner.component';
import { ActivateEmailComponent } from './components/activate-email/activate-email.component';
import { ShowPasswordComponent } from './components/show-password/show-password.component';
import { CustomRadioButton } from './directives/checkbox-icon.directive';
import { DownloadFileDirective } from './directives/download-file.directive';
import { PaymentFailedBannerComponent } from './components/payment-failed-banner/payment-failed-banner.component';
import { UserActivationBannerComponent } from './components/user-activation-banner/user-activationn-banner.component';
import { SaleBannerComponent } from './components/sale-banner/sale-banner.component';

@NgModule({
  imports: [CommonModule, MaterialModule, RouterModule],
  declarations: [
    DownloadFileDirective,
    SubscriptionOverviewComponent,
    CustomRadioButton,
    UserActivationBannerComponent,
    EmailActivationBannerComponent,
    ManagedUserLandingComponent,
    AccountActivationBannerComponent,
    CancelSubscriptionBannerComponent,
    HelpdeskComponent,
    CurrentPlanBannerComponent,
    UpgradePlanComponent,
    ActivateEmailComponent,
    ConfirmDialogComponent,
    ShowPasswordComponent,
    SpinnerComponent,
    PaymentFailedBannerComponent,
    LogoutComponent,
    SaleBannerComponent,
  ],
  exports: [
    DownloadFileDirective,
    SubscriptionOverviewComponent,
    CustomRadioButton,
    EmailActivationBannerComponent,
    UserActivationBannerComponent,
    ManagedUserLandingComponent,
    AccountActivationBannerComponent,
    CancelSubscriptionBannerComponent,
    HelpdeskComponent,
    CurrentPlanBannerComponent,
    UpgradePlanComponent,
    ShowPasswordComponent,
    PaymentFailedBannerComponent,
    SpinnerComponent,
    SaleBannerComponent,
  ],
})
export class SharedModule {}
