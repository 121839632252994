import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(private http: HttpClient) {}

  getProfile(): Observable<UserDetailsResponse> {
    return this.http.get<any>(`${environment.apiUrl}/users/me`);
  }

  getPreferences(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/users/preferences`);
  }

  getActiveSubscriptions(): Observable<UserDetailsResponse> {
    return this.http.get<any>(
      `${environment.apiUrl}/subscriptions/active-subscriptions`
    );
  }

  savePreferences(roles, sounds) {
    return this.http.post<any>(
      `${environment.apiUrl}/users/update-preferences`,
      {
        roles,
        sounds,
      }
    );
  }
}

export interface UserDetailsResponse {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  firstName: string;
  lastName: string;
  role: string;
  email: string;
  avatar?: any;
  company?: any;
  address?: any;
  address2?: any;
  city?: any;
  state?: any;
  zipCode?: any;
  coins: number;
  emailActivated: boolean;
  subscriptions: any;
  user_version: string;
  v1_plan: string | null;
  user_activated?: 'Y' | 'N' | null;
  userSoundPack?: any;
  parentUser?: UserDetailsResponse;
  parentUserId?: any;
  emailActivationCode: string | null;
}
