import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';
import {
  AuthenticationService,
  UserDetailsResponse,
} from '../../_shared/services';
import { MatDialog } from '@angular/material/dialog';
import { PurchaseTokenDialogComponent } from '../../subscription/plan/token/purchase-tokens.component';
import { NotificationToasterService } from 'src/app/_shared/services/notification.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements AfterViewInit, OnDestroy, OnInit {
  currentUser: UserDetailsResponse;

  @ViewChild(MatSidenav, { static: true }) sidenav: MatSidenav;

  subs: Subscription[] = [];
  temporaryDisabled = false;
  env = environment;
  isManagedLogin = false;
  isDevEnvironment = this.env.production === false;

  // get isSidenavOpened() {
  //   return this.sidenav.opened;
  // }
  //
  // get hasMenuBar() {
  //   return !this.sidenav.opened || !(this.isSidenavOpened && this.isSidenavFullscreen);
  // }
  //
  // get isSidenavFullscreen() {
  //   return this.sidenav.mode === 'over';
  // }

  constructor(
    private observer: BreakpointObserver,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private toaster: NotificationToasterService
  ) {
    this.currentUser = this.authenticationService.user;
    if (this.currentUser?.parentUserId) {
      this.isManagedLogin = true;
    }
  }
  isDownloading = false;

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params['p'] === 'buy-tokens') {
        this.openTokenPacks();
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  // toggle() {
  //   if (this.isSidenavFullscreen) {
  //     this.temporaryDisabled = true;
  //   }
  //   this.sidenav.toggle().then(r => this.temporaryDisabled = false);
  //
  // }

  ngAfterViewInit() {
    //
    //   this.subs.push(this.observer
    //     .observe(['(max-width: 8000px)'])
    //     .pipe(delay(1))
    //     .subscribe((res) => {
    //       if (res.matches) {
    //         this.sidenav.mode = 'over';
    //         this.temporaryDisabled = true;
    //         this.sidenav.close().then(r => this.temporaryDisabled = false);
    //       } else {
    //         this.sidenav.mode = 'side';
    //         //     this.sidenav.open().then(r => this.temporaryDisabled = false);
    //       }
    //     }));
    //
    //   this.subs.push(this.router.events
    //     .pipe(
    //       filter((e) => e instanceof NavigationEnd)
    //     )
    //     .subscribe(() => {
    //       if (this.isSidenavFullscreen) {
    //         this.temporaryDisabled = true;
    //         this.sidenav.close().then(r => this.temporaryDisabled = false);
    //       }
    //     }));
    //
    //
  }

  openAdd() {
    if (
      this.currentUser?.emailActivationCode ||
      this.currentUser?.user_activated === 'N'
    ) {
      this.toaster.info('Please activate your account to continue');
    } else {
      this.authenticationService.openAdd();
    }
  }

  navigateToSounds() {
    const soundsAppUrl = environment.soundsApp.trim();
    window.location.href = soundsAppUrl;
  }

  downloadApp() {
    this.isDownloading = true;
    const url = 'https://dl.add.app/';

    setTimeout(() => {
      this.isDownloading = false;
    }, 2000);

    window.location.href = url;
  }

  async logout() {
    this.authenticationService.logout();
    // await this.router.navigate(['/login']);
  }

  openTokenPacks() {
    const dialogRef = this.dialog.open(PurchaseTokenDialogComponent, {
      minWidth: '300px',
      maxWidth: '400px',
      height: '100%',
      data: {},
      position: {
        right: '0px',
      },
    });
  }
}
